require("@rails/ujs").start()
require("@rails/activestorage").start()
require("channels")

require("trix")
require("@rails/actiontext")

window.jQuery = $
window.$ = $

import axios from 'axios';

import "bootstrap";
import "jquery-ui";

require("local-time").start()
import 'data-confirm-modal'

import '../javascript/noty';
import '../javascript/bootstrap';
import '../javascript/select2';
import '../javascript/flatpickr'
import '../javascript/fancybox';
import '../javascript/richtext';

window.jQuery = $

// import '../theme/HTWF/scripts/script';
// import '../theme/HTWF/scripts/parallax.min';
// import '../theme/HTWF/scripts/imagesloaded.min';
// import '../theme/HTWF/scripts/jquery.magnific-popup.min';

// import '../theme/HTWF/scripts/flexslider/jquery.flexslider-min';
// import '../theme/HTWF/scripts/jquery.tab-accordion';
// import '../theme/HTWF/scripts/isotope.min';
// import '../theme/HTWF/scripts/jquery.progress-counter';
// import '../theme/HTWF/scripts/smooth.scroll.min';
// import '../theme/HTWF/scripts/social.stream.min';

import "../stylesheets/home";

document.addEventListener("DOMContentLoaded", function() {
  document.querySelectorAll("action-text-attachment").forEach(function(i){
    let src = i.getAttribute("url");
    if(src.indexOf('img.youtube.com/vi/') >= 0){
      let youtube_id = src.split("img.youtube.com/vi/").pop().split("/0.jpg")[0];
      let embed = new DOMParser().parseFromString(`<iframe width="100%" height="315" src="https://www.youtube.com/embed/${youtube_id}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>`, 'text/html').body.firstChild;
      i.replaceWith(embed);
    };
  })
});
